import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import AuthProvider from "./context/auth/auth.context"
import LoadingModalProvider from "./context/loading-modal/loading-modal.contenxt"
import FetchProvider from "./context/fetch-context/fetch.context"

import { QueryClient, QueryClientProvider } from "react-query"
import { ReactQueryDevtools } from "react-query/devtools"

const queryClient = new QueryClient()

ReactDOM.render(
	<React.StrictMode>
		<QueryClientProvider client={queryClient}>
			<FetchProvider>
				<LoadingModalProvider>
					<AuthProvider>
						<App />
					</AuthProvider>
				</LoadingModalProvider>
			</FetchProvider>
			<ReactQueryDevtools initialIsOpen={false} />
		</QueryClientProvider>
	</React.StrictMode>,
	document.getElementById("root")
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
